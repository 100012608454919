import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { deleteProduct, getProductByCategory } from '../../services/product_service';
import CustomDetailButton from '../../components/button/CustomDetailButton';
import CustomDeleteButton from "../../components/button/CustomDeleteButton";
import CustomUpdateButton from "../../components/button/CustomUpdateButton";
import { arrayBufferToBase64 } from "../../helpers/Helpers";
import NoPhoto from "../../assets/images/no-photo.png";
import NoProduct from "../../assets/images/product-not-found.png";
import { tokenIsValid } from "../../services/auth_service"

function Product() {

    const { category_name } = useParams();
    const navigate = useNavigate();
    const [productlists, setProductList] = useState([]);
    const [token, setToken] = useState(null);

    useEffect(() => {
        getData();
        tokenControl();
    }, []);

    const tokenControl = async () => {
        const isValid = await tokenIsValid(localStorage.getItem('auth_token'));
        setToken(isValid);
    }

    const getData = async () => {
        const id = category_name.split("=")[1];
        const result = await getProductByCategory(id);
        setProductList(result);
    }

    const productDelete = async (id) => {
        await deleteProduct(id);
        setProductList(prevList => prevList.filter(product => product.id !== id));
    }

    const productUpdate = (product) => {
        navigate('/product/update/' + product.id, { state: { product } })
    }

    const productDetail = (product) => {
        navigate('/product/detail/' + product.id, { state: { product } })
    }

    return (
        <>
            {
                (productlists.length !== 0 ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-4 px-6 sm:px-12 md:px-16 lg:px-24 mt-12 mb-12">
                        {
                            productlists.map((productlist) => (
                                <div key={productlist.id} className="flex flex-col border rounded-lg p-4 w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow">
                                    {token ? (
                                        <div className="flex justify-between">
                                            <CustomDeleteButton onClick={() => productDelete(productlist.id)} label={'Sil'} />
                                            <CustomUpdateButton onClick={() => productUpdate(productlist)} label={'Güncelle'} />
                                        </div>
                                    ) : null}
                                    {
                                        productlist.photo_url != null ? (
                                            <img className="p-8 rounded-t-lg object-cover w-full h-48 sm:h-64 md:h-48 lg:h-56 xl:h-64" src={productlist.photo_url} alt={productlist.product_name} />
                                        ) : (
                                            <img className="p-8 rounded-t-lg object-cover w-full h-48 sm:h-64 md:h-48 lg:h-56 xl:h-64" src={NoPhoto} alt={productlist.product_name} />
                                        )
                                    }
                                    <div className="flex justify-center px-5 pb-5">
                                        <h5 className="flex items-center justify-center text-2xl font-bold tracking-tight text-gray-900">{productlist.product_name}</h5>
                                    </div>
                                    <div className="flex justify-center">
                                        <CustomDetailButton onClick={() => productDetail(productlist)} label={'Detay'} />
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                ) : (
                    <div className="flex justify-center items-center h-screen">
                        <img className="max-w-full max-h-full" src={NoProduct} alt="no-product" />
                    </div>
                ))
            }

        </>
    );
};

export default Product;